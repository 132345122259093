import xdcLogo from 'assets/images/xdc-logo.png'
import xdcLogoTest from 'assets/images/xdc-logo-test.png'
import ms from 'ms.macro'

import { SupportedChainId, SupportedL1ChainId, SupportedL2ChainId } from './chains'
import { XDC_MAINLIST, XDC_TESTLIST } from './lists'

export const AVERAGE_L1_BLOCK_TIME = ms`12s`

export enum NetworkType {
  L1,
  L2,
}
interface BaseChainInfo {
  readonly networkType: NetworkType
  readonly blockWaitMsBeforeWarning?: number
  readonly docs: string
  readonly bridge?: string
  readonly explorer: string
  readonly infoLink: string
  readonly logoUrl: string
  readonly circleLogoUrl?: string
  readonly squareLogoUrl?: string
  readonly label: string
  readonly helpCenterUrl?: string
  readonly nativeCurrency: {
    name: string // e.g. 'Goerli ETH',
    symbol: string // e.g. 'gorETH',
    decimals: number // e.g. 18,
  }
  readonly color?: string
  readonly backgroundColor?: string
}

interface L1ChainInfo extends BaseChainInfo {
  readonly networkType: NetworkType.L1
  readonly defaultListUrl: string
}

export interface L2ChainInfo extends BaseChainInfo {
  readonly networkType: NetworkType.L2
  readonly bridge: string
  readonly statusPage?: string
  readonly defaultListUrl: string
}

type ChainInfoMap = { readonly [chainId: number]: L1ChainInfo } & {
  readonly [chainId in SupportedL1ChainId]: L1ChainInfo
}

const CHAIN_INFO: ChainInfoMap = {
  [SupportedChainId.XDC]: {
    networkType: NetworkType.L1,
    blockWaitMsBeforeWarning: ms`10m`,
    bridge: '',
    docs: 'https://howto.xinfin.org/',
    explorer: 'https://explorer.xinfin.network',
    infoLink: process.env.REACT_APP_INFO_PAGE + '/#/xinfin/',
    label: 'Mainnet',
    logoUrl: xdcLogo,
    circleLogoUrl: xdcLogo,
    squareLogoUrl: xdcLogo,
    nativeCurrency: { name: 'XDC', symbol: 'XDC', decimals: 18 },
    defaultListUrl: XDC_MAINLIST,
  },
  [SupportedChainId.XDC_TESTNET]: {
    networkType: NetworkType.L1,
    blockWaitMsBeforeWarning: ms`10m`,
    bridge: '',
    docs: 'https://howto.xinfin.org/',
    explorer: 'https://explorer.apothem.network',
    infoLink: process.env.REACT_APP_INFO_PAGE + '/#/apothem/',
    label: 'Testnet',
    logoUrl: xdcLogoTest,
    circleLogoUrl: xdcLogoTest,
    squareLogoUrl: xdcLogoTest,
    nativeCurrency: { name: 'XDC', symbol: 'XDC', decimals: 18 },
    defaultListUrl: XDC_TESTLIST,
  },
}

export function getChainInfo(chainId: SupportedL1ChainId): L1ChainInfo
export function getChainInfo(chainId: SupportedChainId): L1ChainInfo
export function getChainInfo(
  chainId: SupportedChainId | SupportedL1ChainId | SupportedL2ChainId | number | undefined
): L1ChainInfo | L2ChainInfo

/**
 * Overloaded method for returning ChainInfo given a chainID
 * Return type varies depending on input type:
 * number | undefined -> returns chaininfo | undefined
 * SupportedChainId -> returns L1ChainInfo | L2ChainInfo
 * SupportedL1ChainId -> returns L1ChainInfo
 * SupportedL2ChainId -> returns L2ChainInfo
 */
export function getChainInfo(chainId: any): any {
  if (chainId) {
    return CHAIN_INFO[chainId] ?? undefined
  }
  return undefined
}

const MAINNET_INFO = CHAIN_INFO[SupportedChainId.XDC]
export function getChainInfoOrDefault(chainId: number | undefined) {
  return getChainInfo(chainId) ?? MAINNET_INFO
}
