import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import { useState } from 'react'
import styled from 'styled-components/macro'

import { useToggleFiatOnramp } from '../../state/application/hooks'
import { SmallButtonPurple } from '../Button'
import { RowBetween, RowFixed } from '../Row'
import SettingsTab from '../Settings'
import Tooltip from '../Tooltip'

const StyledSwapHeader = styled(RowBetween)`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.textSecondary};
`

export default function SwapHeader({
  autoSlippage,
  chainId,
  account,
}: {
  autoSlippage: Percent
  chainId?: number
  account?: string
}) {
  const toggleFiatOnramp = useToggleFiatOnramp()
  const [showHover, setShowHover] = useState(false)

  return (
    <StyledSwapHeader>
      <Tooltip show={showHover && !account} text={<Trans>Please connect an account before making a purchase.</Trans>}>
        <div onMouseEnter={() => setShowHover(true)} onMouseLeave={() => setShowHover(false)}>
          <SmallButtonPurple onClick={toggleFiatOnramp} disabled={!account}>
            <Trans>Buy XDC</Trans>
          </SmallButtonPurple>
        </div>
      </Tooltip>
      <RowFixed>
        <SettingsTab autoSlippage={autoSlippage} chainId={chainId} />
      </RowFixed>
    </StyledSwapHeader>
  )
}
